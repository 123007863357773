import"core-js/modules/es.array.map.js";import"core-js/modules/es.array.sort.js";import"core-js/modules/es.array.concat.js";import"core-js/modules/es.symbol.js";import"core-js/modules/es.symbol.description.js";import"core-js/modules/es.array.filter.js";import"core-js/modules/es.object.to-string.js";/**
 * Status normalizer:
 * Converts API statuses into our internal format.
 * @see {@link https://docs.joinmastodon.org/entities/status/}
 */import{Map as ImmutableMap,List as ImmutableList,Record as ImmutableRecord,fromJS}from'immutable';import{normalizeAttachment}from'soapbox/normalizers/attachment';import{normalizeCard}from'soapbox/normalizers/card';import{normalizeEmoji}from'soapbox/normalizers/emoji';import{normalizeMention}from'soapbox/normalizers/mention';import{normalizePoll}from'soapbox/normalizers/poll';export var EventRecord=ImmutableRecord({name:'',start_time:null,end_time:null,join_mode:null,participants_count:0,location:null,join_state:null,banner:null,links:ImmutableList()});// https://docs.joinmastodon.org/entities/status/
export var StatusRecord=ImmutableRecord({account:null,application:null,bookmarked:false,card:null,content:'',created_at:'',edited_at:null,emojis:ImmutableList(),favourited:false,favourites_count:0,group:null,in_reply_to_account_id:null,in_reply_to_id:null,id:'',language:null,media_attachments:ImmutableList(),mentions:ImmutableList(),muted:false,pinned:false,pleroma:ImmutableMap(),poll:null,quote:null,quotes_count:0,reblog:null,reblogged:false,reblogs_count:0,replies_count:0,sensitive:false,spoiler_text:'',tags:ImmutableList(),uri:'',url:'',visibility:'public',event:null,// Internal fields
contentHtml:'',expectsCard:false,filtered:false,hidden:false,search_index:'',spoilerHtml:'',translation:null});var normalizeAttachments=function normalizeAttachments(status){return status.update('media_attachments',ImmutableList(),function(attachments){return attachments.map(normalizeAttachment);});};var normalizeMentions=function normalizeMentions(status){return status.update('mentions',ImmutableList(),function(mentions){return mentions.map(normalizeMention);});};// Normalize emojis
var normalizeEmojis=function normalizeEmojis(entity){return entity.update('emojis',ImmutableList(),function(emojis){return emojis.map(normalizeEmoji);});};// Normalize the poll in the status, if applicable
var normalizeStatusPoll=function normalizeStatusPoll(status){if(status.hasIn(['poll','options'])){return status.update('poll',ImmutableMap(),normalizePoll);}else{return status.set('poll',null);}};// Normalize card
var normalizeStatusCard=function normalizeStatusCard(status){if(status.get('card')){return status.update('card',ImmutableMap(),normalizeCard);}else{return status.set('card',null);}};// Fix order of mentions
var fixMentionsOrder=function fixMentionsOrder(status){var mentions=status.get('mentions',ImmutableList());var inReplyToAccountId=status.get('in_reply_to_account_id');// Sort the replied-to mention to the top
var sorted=mentions.sort(function(a,_b){if(a.get('id')===inReplyToAccountId){return-1;}else{return 0;}});return status.set('mentions',sorted);};// Add self to mentions if it's a reply to self
var addSelfMention=function addSelfMention(status){var accountId=status.getIn(['account','id']);var isSelfReply=accountId===status.get('in_reply_to_account_id');var hasSelfMention=accountId===status.getIn(['mentions',0,'id']);if(isSelfReply&&!hasSelfMention&&accountId){var mention=normalizeMention(status.get('account'));return status.update('mentions',ImmutableList(),function(mentions){return ImmutableList([mention]).concat(mentions);});}else{return status;}};// Move the quote to the top-level
var fixQuote=function fixQuote(status){return status.withMutations(function(status){status.update('quote',function(quote){return quote||status.getIn(['pleroma','quote'])||null;});status.deleteIn(['pleroma','quote']);status.update('quotes_count',function(quotes_count){return quotes_count||status.getIn(['pleroma','quotes_count'],0);});status.deleteIn(['pleroma','quotes_count']);});};// Workaround for not yet implemented filtering from Mastodon 3.6
var fixFiltered=function fixFiltered(status){status.delete('filtered');};/** If the status contains spoiler text, treat it as sensitive. */var fixSensitivity=function fixSensitivity(status){if(status.get('spoiler_text')){status.set('sensitive',true);}};// Normalize event
var normalizeEvent=function normalizeEvent(status){if(status.getIn(['pleroma','event'])){var firstAttachment=status.get('media_attachments').first();var banner=null;var mediaAttachments=status.get('media_attachments');if(firstAttachment&&firstAttachment.description==='Banner'&&firstAttachment.type==='image'){banner=normalizeAttachment(firstAttachment);mediaAttachments=mediaAttachments.shift();}var links=mediaAttachments.filter(function(attachment){return attachment.pleroma.get('mime_type')==='text/html';});mediaAttachments=mediaAttachments.filter(function(attachment){return attachment.pleroma.get('mime_type')!=='text/html';});var event=EventRecord(status.getIn(['pleroma','event']).set('banner',banner).set('links',links));status.set('event',event).set('media_attachments',mediaAttachments);}};export var normalizeStatus=function normalizeStatus(status){return StatusRecord(ImmutableMap(fromJS(status)).withMutations(function(status){normalizeAttachments(status);normalizeMentions(status);normalizeEmojis(status);normalizeStatusPoll(status);normalizeStatusCard(status);fixMentionsOrder(status);addSelfMention(status);fixQuote(status);fixFiltered(status);fixSensitivity(status);normalizeEvent(status);}));};